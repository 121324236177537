let apis = {   
    staging: {
        regionUrl: "https://staging.smartbear.com/services/interprice/interprice.aspx/?getZone=1",
        interpriceUrl: "https://staging.smartbear.com/rest/customtableitem.sb.InternationalPricing?format=json&columns=ProductCode,Price_Euro,Price_USD,Price_USD_NORAM&hash=2194de77084977d086fee461482c7b31d7a5a17d1b8216dc7c4a85b8f2144d43",
        productsUrl: "https://staging.smartbear.com/rest/customtableitem.sb.Products?format=json&columns=Name,Price,Url,Description,Rank,IconCSSClass,LearnMoreLink,LearnMoreCaption,Solutions,MarketPlaces,Platforms,Hubs,ProductFamilyCSSClass,LinkToPricing&Where=eCommerceEnabled=1&hash=abc557b120695f47c9267e800ea2e6edc0267199526fc01e6308886be38c2c6c",
        solutionsUrl: "https://staging.smartbear.com/rest/customtableitem.sb.ProductSolutions?format=json&columns=Name&hash=97be180f2aee5dc4b63ff0f90c40e5714d8a510b04eaf2ac0b275118e6c1d265",
        platformsUrl: "https://staging.smartbear.com/rest/customtableitem.sb.ProductPlatforms?format=json&columns=Name&hash=d58a25e0d9e9e36b4d45f816af5946f9130e424b72bfac7564a6d097d5aa59b4",
        marketPlaceUrl: "https://staging.smartbear.com/rest/customtableitem.sb.ProductMarketPlace?format=json&columns=MarketPlace&hash=96ea8e75bdf453a64b2a8ef2f155219e514746ab18c6c44a6d1220a8a5539001",
        hubsUrl: "https://staging.smartbear.com/rest/customtableitem.sb.ProductHubs?format=json&columns=Name&hash=2d6c9cf8b076c82d3c0c3f6d67b31aec1734ca4d1d3bb104a00ac88844cf080e"
    },
    production: {
        regionUrl: "https://staging.smartbear.com/services/interprice/interprice.aspx/?getZone=1",
        interpriceUrl: "https://smartbear.com/rest/customtableitem.sb.InternationalPricing?format=json&columns=ProductCode,Price_Euro,Price_USD,Price_USD_NORAM&hash=8a01cac91cec3c46565b6dd8c7c88513a14d6221bcba8d884ea57aa0737adb80",
        productsUrl: "https://smartbear.com/rest/customtableitem.sb.Products?format=json&columns=Name,Price,Url,Description,Rank,IconCSSClass,LearnMoreLink,LearnMoreCaption,Solutions,MarketPlaces,Platforms,Hubs,ProductFamilyCSSClass,LinkToPricing&Where=eCommerceEnabled=1&hash=acef3dce2e87aa89983c20dcfdba32f0770363b3985d06589789659318bea129",
        solutionsUrl: "https://smartbear.com/rest/customtableitem.sb.ProductSolutions?format=json&columns=Name&hash=fa59354a6e0ae3680a78999d592d010b3cbfaeb0519677f84c422435495d57c4",
        platformsUrl: "https://smartbear.com/rest/customtableitem.sb.ProductPlatforms?format=json&columns=Name&hash=b90ef939bf65418eca53d555fd30431704cd77bf541dcb0827bca94b104483b7",
        marketPlaceUrl: "https://smartbear.com/rest/customtableitem.sb.ProductMarketPlace?format=json&columns=MarketPlace&hash=5bae792e65647d6b6733cae068d89d261a82edb3408dbed1b82a89680b344f82",
        hubsUrl: "https://smartbear.com/rest/customtableitem.sb.ProductHubs?format=json&columns=Name&hash=bf782b70e2951c9a6a87124cf61d61dd05933f3c9bb62af715cefa5fd316ac00"
      }
};

apis.local = apis.staging;

/**
 * Detect current environment
 */
let currentEnv = 'local';
 if (window.location.hostname === "staging.smartbear.com") {
    currentEnv = "staging";
} else if (window.location.hostname === "smartbear.com") {
    currentEnv = "production";
}

const config = {
    env: currentEnv,    
    api: apis[currentEnv]
}

export default config;