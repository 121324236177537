export const setProducts = products => ({
  type: "SET_PRODUCTS",
  payload: products
});

export const setAddons = addons => ({
  type: "SET_ADDONS",
  payload: addons
});

export const setInterPrice = interprice => ({
  type: "SET_INTER_PRICE",
  payload: interprice
});

export const setRegion = region => ({
  type: "SET_REGION",
  payload: region
});

export const setQueryProducts = products => ({
  type: "SET_QUERY_PRODUCTS",
  payload: products
});

// export const setFiltArea = area => ({
//   type: "SET_FILT_AREA",
//   payload: area
// });

export const setSolutions = solutions => ({
  type: "SET_SOLUTIONS",
  payload: solutions
});

export const setPlatforms = platforms => ({
  type: "SET_PLATFORMS",
  payload: platforms
});

export const setMarketPlaces = marketPlaces => ({
  type: "SET_MARKETPLACES",
  payload: marketPlaces
});

export const setHubs = hubs => ({
  type: "SET_HUBS",
  payload: hubs
});
