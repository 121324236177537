const initialState = {
    hubs: null,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case "SET_HUBS":
        return {
          ...state,
          hubs: action.payload,
        };
      default:
        return state;
    }
  };
  