import React, { Component } from "react";

import "./LeftMenu.scss";

import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { Accordion, Form, Menu, Checkbox } from "semantic-ui-react";

import { setFilter, clearFilters } from "../../actions/filterActions";

import ProductsStore from '../../data/products';

import orderBy from "lodash/orderBy";
import { isArray } from "lodash";


export class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: !!this.props.products ? this.props.products.activeIndex : -1,
    };
    this.handleFilter = this.handleFilter.bind(this);
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "Filter",
      label: value
    }, ['gadev']);
  }

  handleChange = (e, data) => {
    if (data) {

    switch(data.areaid) {
      case 0: 
        this.props.setFilter(data.name, "Hubs");
        this.onAnalyticsSend('Hubs_' + data.name)
        break;
      case 1: 
        this.props.setFilter(data.name, "Solutions");
        this.onAnalyticsSend('Solutions_' + data.name);
        break;
      case 2: 
        this.props.setFilter(data.name, "Platforms");
        this.onAnalyticsSend('Platforms_' + data.name);
        break;
      case 3: 
        this.props.setFilter(data.name, "MarketPlaces");
        this.onAnalyticsSend('MarketPlaces_' + data.name);
        break;
      case 4: 
        this.props.setFilter(data.name, "ProductAddons");
        this.onAnalyticsSend('ProductAddons_' + data.name);
        break;
      default:
        break;
    }}
  };

  handleFilter = (data) => data.map((el) => {
    if (el.ProductCount && el.ProductCount > 0 ) {
      el.disabled = false;
    return el}
    else {
    el.disabled = true;
    return el}
  });

  HubForm = props => {
    let { hubs, selectedItems, activeIndex, products } = this.props;

    if (hubs) {
      
      hubs.forEach((hub) => {
        let filtCount = 0;

        // add products count for each solution
        products.forEach((product) => {
          if (product.Hubs && product.Hubs.includes(hub.Name)) {
            filtCount++;
          }
        });

        // check hubs
        hub.isChecked = false;
        if ( selectedItems.length > 0 ) { 
          selectedItems.forEach((selectedItem) => {           
            if (!!selectedItem.Hubs && selectedItem.Hubs.toLowerCase() === hub.Name.toLowerCase())
              hub.isChecked = true;
          });
        }

        hub.ProductCount = filtCount;
      });
    }
    let filteredHubs = [];
    if ( hubs ){
      filteredHubs = this.handleFilter(hubs)
    }

    return (
      <Form>
        <div className="filter-title"><div className="filter-title-text">Hubs</div></div>
        <Form.Group grouped>
          {filteredHubs ? filteredHubs.map((hub, key) => {
            if (hub.Name !== 'Open Source') {
              return (
                <div className="checkbox" key={key}>
                <Checkbox
                  id={`${hub.Name}` + key}
                  onChange={this.handleChange.bind()}
                  className={"checkbox-item"}
                  checked={hub.isChecked}
                  areaid={0}
                  label={hub.Name}
                  name={hub.Name}
                  value={hub.Name}
                  disabled={hub.disabled}
                /> 
                <div className={`checkbox-item-count ${hub.disabled ? 'disabled' : ''}`}>({hub.ProductCount})</div>
                </div>
              );}
            })
            : ""}
        </Form.Group>
      </Form>
    );
  };
  
  SolutionForm = props => {
    let { solutions, selectedItems, activeIndex, products } = this.props;

    if (solutions) {
      
      solutions.forEach((solution) => {
        let filtCount = 0;

        // add products count for each solution
        products.forEach((product) => {
          if (product.Solutions && product.Solutions.includes(solution.Name)) {
            filtCount++;
          }
        });

        // check solution
        solution.isChecked = false;
        if ( selectedItems.length > 0 ) { 
          selectedItems.forEach((selectedItem) => {           
            if (!!selectedItem.Solutions && selectedItem.Solutions.toLowerCase() === solution.Name.toLowerCase())
              solution.isChecked = true;
          });
        }

        solution.ProductCount = filtCount;
      });
    }
    let filteredSolutions = [];
    if ( solutions ){
      filteredSolutions = this.handleFilter(solutions)
    }

    return (
      <Form>
        <div className="filter-title"><div className="filter-title-text">Solutions</div></div>
        <Form.Group grouped>
          {filteredSolutions ? filteredSolutions.map((solution, key) => {
            if (solution.Name !== 'Open Source') {
              return (
                <div className="checkbox" key={key}>
                <Checkbox
                  id={`${solution.Name}` + key}
                  onChange={this.handleChange.bind()}
                  className={"checkbox-item"}
                  checked={solution.isChecked}
                  areaid={1}
                  label={solution.Name}
                  name={solution.Name}
                  value={solution.Name}
                  disabled={solution.disabled}
                /> 
                <div className={`checkbox-item-count ${solution.disabled ? 'disabled' : ''}`}>({solution.ProductCount})</div>
                </div>
              );}
            })
            : ""}
        </Form.Group>
      </Form>
    );
  };

  PlatformsForm = props => {
    let { platforms, selectedItems, products } = this.props;

    if (platforms) {
      
      platforms.forEach((platform) => {
        let filtCount = 0;

        // add products count for each platform
        products.forEach((product) => {
          if (product.Platforms && product.Platforms.includes(platform.Name)) {
            filtCount++;
          }
        });

        // check platform
        platform.isChecked = false;
        if ( selectedItems.length > 0 ) { 
          selectedItems.forEach((selectedItem) => {
           // if (!!selectedItem.Platforms && selectedItem.Platforms.name === platform.Name)            
            if (!!selectedItem.Platforms && selectedItem.Platforms.toLowerCase()===platform.Name.toLowerCase())
            platform.isChecked = true;
          });
        }

        platform.ProductCount = filtCount;
      });
    }
    let filteredPlatforms = [];
    if (platforms) {
      filteredPlatforms = this.handleFilter(platforms)
    }

    return (
      <Form>
        <div className="filter-title"><div className="filter-title-text">Platforms</div></div>
        <Form.Group grouped>
          {filteredPlatforms ? filteredPlatforms.map((platform, key) => {
              return (
                <div className="checkbox" key={key}>
                <Checkbox
                  id={`${platform.Name}` + key}
                  onChange={this.handleChange.bind()}
                  className={"checkbox-item"}
                  areaid={2}
                  checked={platform.isChecked}
                  label={platform.Name}
                  name={platform.Name}
                  value={platform.Name}
                  disabled={platform.disabled}
                />
                <div className={`checkbox-item-count ${platform.disabled ? 'disabled' : ''}`}>({platform.ProductCount})</div>
                </div>
              );
            })
            : ""}
        </Form.Group>
      </Form>
    );
  };

  MarketPlacesForm = props => {
    let { marketPlaces, selectedItems, products } = this.props;

    if (marketPlaces) {
      
      marketPlaces.forEach((marketPlace) => {
        let filtCount = 0;

        // add products count for each marketPlaces
        products.forEach((product) => {
          if (product.MarketPlaces && product.MarketPlaces.includes(marketPlace.MarketPlace)) {
            filtCount++;
          }
        });

        // check marketPlaces
        marketPlace.isChecked = false;
        if ( selectedItems.length > 0 ) { 
          selectedItems.forEach((selectedItem) => { 
          if (!!selectedItem.MarketPlaces && selectedItem.MarketPlaces.toLowerCase()===marketPlace.MarketPlace.toLowerCase())
            marketPlace.isChecked = true;
          });
        }

        marketPlace.ProductCount = filtCount;
      });
    }
    let filteredMarketPlaces = [];
    if (marketPlaces) {
      filteredMarketPlaces = this.handleFilter(marketPlaces)
    }

    return (
      <Form>
        <div className="filter-title"><div className="filter-title-text">MarketPlaces</div></div>
        <Form.Group grouped>
          {filteredMarketPlaces ? filteredMarketPlaces.map((marketPlace, key) => {
              return (
                <div className="checkbox" key={key}>
                <Checkbox
                  id={`${marketPlace.MarketPlace}` + key}
                  onChange={this.handleChange.bind()}
                  className={"checkbox-item"}
                  areaid={3}
                  checked={marketPlace.isChecked}
                  label={marketPlace.MarketPlace}
                  name={marketPlace.MarketPlace}
                  value={marketPlace.MarketPlace}
                  disabled={marketPlace.disabled}
                />
                <div className={`checkbox-item-count ${marketPlace.disabled ? 'disabled' : ''}`}>({marketPlace.ProductCount})</div>
                </div>
              );
            })
            : ""}
        </Form.Group>
      </Form>
    );
  };

  ProductAddonsForm = props => {
    let { products, selectedItems } = this.props;
    let productsConfig = ProductsStore.getProducts();  

    if (productsConfig) {
      productsConfig.forEach((product)=>{
        let filtCount = 0; 
      products.forEach((prod) => {      
        // add products count for each marketPlaces
          if (product.name === prod.Name) {
            filtCount++;
          }
      });

        
        product.isChecked = false;
        if ( selectedItems.length > 0 ) { 
          selectedItems.forEach((selectedItem) => {
           // if (!!selectedItem.Platforms && selectedItem.Platforms.name === platform.Name)            
            if (!!selectedItem.ProductAddons && selectedItem.ProductAddons.toLowerCase() === product.name.toLowerCase())
            product.isChecked = true;
          });
        }

        product = product.ProductCount = filtCount;
    })
    let filteredProducts = [];
    if (productsConfig) {
      filteredProducts = this.handleFilter(productsConfig)
    }

    return (
      <Form>
        <div className="filter-title"><div className="filter-title-text">Product Add-ons</div><div className="filter-title-line"></div></div>
        <Form.Group grouped>
          {filteredProducts ? filteredProducts.map((product, key) => {
            if (isArray(product.addons)) {
              return (
                <div className="checkbox" key={key}>
                <Checkbox
                  id={`${product.name}` + key}
                  onChange={this.handleChange.bind()}
                  className={"checkbox-item"}
                  areaid={4}
                  checked={product.isChecked}
                  label={product.name}
                  name={product.name}
                  value={product.name}
                  disabled={product.disabled}
                />
                <div className={`checkbox-item-count ${product.disabled ? 'disabled' : ''}`}></div>
                </div>
              );}
            })
            : ""}
        </Form.Group>
      </Form>
    );
  };
  }
  render() {
    const { activeIndex, show } = this.props;
    
    return (
      <div className={`accordion-filter ${ show === false ? '' : 'show'}`}>
        <div className="accordion-title px-3"><i className="fal fa-filter"></i>Filter by</div>
        <Menu fluid vertical>
        <Menu.Item className="borderless">
            <Accordion.Content
              active={activeIndex === 0}
              content={this.HubForm(this.props, activeIndex)}
            />
          </Menu.Item>

          <Menu.Item className="borderless">
            {/* <Accordion.Title
              active={activeIndex === 0}
              content="Solutions"
              index={0}
              icon={activeIndex === 0 ? 'chevron down' : 'chevron up'}
              onClick={this.handleClick.bind(this, this.props)}
            /> */}
            <Accordion.Content
              active={activeIndex === 1}
              content={this.SolutionForm(this.props, activeIndex)}
            />
          </Menu.Item>

          <Menu.Item className="borderless">
            <Accordion.Content
              active={activeIndex === 2}
              content={this.PlatformsForm(this.props, activeIndex)}
            />
          </Menu.Item>

          <Menu.Item className="borderless">
            <Accordion.Content
              active={activeIndex === 3}
              content={this.MarketPlacesForm(this.props, activeIndex)}
            />
          </Menu.Item>

          <Menu.Item className="borderless">
            <Accordion.Content
              active={activeIndex === 4}
              content={this.ProductAddonsForm(this.props, activeIndex)}
            />
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    filter: { selectedItems }
  } = state;
  const { 
    products: { activeIndex }
  } = state;
  return {
    selectedItems,
    activeIndex 
  };
};

const mapDispatchToProps = {
  setFilter,
  clearFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
